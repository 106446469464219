.extraModal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
#extraLeave-modal {
    position: absolute;
    top: 5rem;
    right: 5rem;
    font-size: 3rem;
    cursor: pointer;
    transition: all .25s ease;
}
#extraLeave-modal:hover {
    transform: rotate(-90deg);
}
.extraModal-wrapper {
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: .75rem;
    background-color: #222;
    animation: extraModalAnim .65s ease forwards;
    margin-right: 75rem;
    opacity: 0;
}
@keyframes extraModalAnim {
    60% {opacity: 1}
    100% {opacity: 1; margin-right: 0}
}
.extraModal-image-wrapper {
    width: 35%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
@keyframes extraModalFade {
    100% {opacity: 1}
}
.extraImages-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.extraImages-wrapper img {
    margin: .5rem 0;
    margin-right: -2rem;
    border-radius: .5rem;
    width: 43rem;
    height: auto;
    animation: extraModalFade 1s ease-in-out 1s forwards;
    opacity: 0;
}
.extraModal-text-box {
    border-radius: .5rem;
    width: 55%;
    height: 95%;
    margin-left: 5%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: #373737;
    animation: extraModalScale .65s ease-in-out .35s forwards;
    opacity: 0;
    transform: scale(.75);
}
@keyframes extraModalScale {
    100% {opacity: 1; transform: scale(1)}
}
.extraModal-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 3rem;
}
.extraModal-title {
    font-size: 4rem;
    padding: 1.25rem 0 4.5rem;
}
.extraModal-description {
    font-size: 1.8rem;
    line-height: 2.85rem;
    padding: 0 3.5rem 2.75rem;
    text-align: center;
}

/* YARD IMAGES */
.extraYard {
    height: 100%;
    width: 100%;
    margin-right: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: extraModalFade 1s ease-in-out 1s forwards;
}
.extraYard h1 {
    font-size: 2.75rem;
    color: #ddd;
}
.extraYard p {
    font-size: 1.375rem;
    color: #999;
    text-align: center;
    padding: 1.75rem 0 2.65rem;
    font-style: italic;
}
.extraYardImgCompare {
    width: 41rem;
    height: 30rem;
    box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, .15);
    border-radius: .25rem;
}

/* RESPONSIVE */
@media (max-width: 1450px) {
    #extraLeave-modal {
        top: 3.5rem;
        right: 3.5rem;
    }
    .extraModal-wrapper {
        height: 85%;
        width: 85%;
        padding: 1.5rem;
    }
    .extraModal-image-wrapper {
        width: 45%;
    }
    .extraModal-text-box {
        width: 50%;
        margin-left: 3.5%;
    }
}
@media (max-width: 1350px) {
    .extraModal-image-wrapper {
        width: 42.5%;
    }
    .extraModal-text-box {
        width: 52.5%;
        margin-left: 3%;
    }
    .extraImages-wrapper {
        width: 45rem;
    }
}
@media (max-width: 1300px) {
    #extraLeave-modal {
        top: 2rem;
        right: 2rem;
        font-size: 2.65rem;
    }
    .extraModal-wrapper {
        height: 90%;
        width: 90%;
        padding: 1.5rem;
        margin-left: -1rem;
    }
    .extraImages-wrapper img {
        margin: .5rem 0;
        margin-right: -.75rem;
        width: 40rem;
        height: auto;
    }
}
@media (max-width: 1250px) {
    .extraModal-description {
        margin-bottom: 1.5rem;
        font-size: 1.85rem;
        line-height: 2.85rem;
    }
    .extraModal-title {
        font-size: 3.75rem;
    }
    .extraYard h1 {
        font-size: 2.425rem;
        text-align: center;
    }
    .extraYard p {
        font-size: 1.35rem;
    }
    .extraYardImgCompare {
        width: 35rem;
        height: 25.5rem;
    }
    .extraYardWrap {
        margin-left: 2.5rem;
    }
}
@media (max-width: 1200px) {
    .extraModal-image-wrapper {
        width: 40%;
    }
    .extraModal-text-box {
        width: 55%;
        margin-left: 3.5%;
    }
    .extraImages-wrapper {
        width: 40rem;
    }
}
@media (max-width: 1100px) {
    .extraModal-container {
        overflow: auto;
        display: block;
        height: 100vh;
    }
    #extraLeave-modal {
        top: 2.5rem;
        right: 2.5rem;
        font-size: 3rem;
        z-index: 9999;
    }
    .extraModal-wrapper {
        width: 100vw;
        height: 105rem;
        border-radius: 0;
        margin-left: 0;
        justify-content: flex-end;
        flex-direction: column-reverse;
        gap: 2.2rem;
    }
    .extraModal-text-box {
        margin-top: 2.5rem;
        height: 57.5rem;
        width: 60rem;
        margin-left: 0;
    }
    .extraModal-image-wrapper {
        height: 42.5rem;
        margin-bottom: 2.5rem;
        width: 100%;
    }
    .extraImages-wrapper {
        width: 60rem;
    }
    .extraImages-wrapper img {
        margin-top: 2rem;
        margin-right: 0rem;
        width: 52.5rem;
    }
}
@media (max-width: 750px) {
    #extraLeave-modal {
        top: 1.5rem;
        right: 1.5rem;
        font-size: 2.5rem;
    }
}
@media (max-width: 650px) {
    .extraModal-wrapper {
        height: 100rem;
        gap: 2rem;
    }
    #extraLeave-modal {
        top: 1.5rem;
        right: 1.5rem;
        font-size: 2.5rem;
    }
    .extraModal-text-box {
        margin-top: 4rem;
        height: 52.5rem;
        width: 40rem;
    }
    .extraModal-image-wrapper {
        height: 40rem;
    }
    .extraImages-wrapper {
        width: 40rem;
    }
    .extraModal-text-wrapper {
        margin-top: 1.75rem;
        line-height: 1.65;
    }
    .extraModal-title {
        font-size: 2.65rem;
        padding: 1.25rem 0 2.75rem;
    }
    .extraModal-description {
        font-size: 1.45rem;
        line-height: 2.35rem;
        height: fit-content;
        margin-bottom: 1rem;
    }
    .extraImages-wrapper img {
        margin-top: 0;
        margin-right: 0rem;
        width: 38.5rem;
    }
}
@media (max-width: 480px) {
    .extraModal-wrapper {
        height: 102.5rem;
    }
    #extraLeave-modal {
        top: 1.75rem;
        right: 1.75rem;
    }
    .extraModal-title {
        font-size: 2.5rem;
    }
    .extraModal-text-box {
        height: 55rem;
        width: 36rem;
    }
    .extraModal-image-wrapper {
        margin-top: 2rem;
        height: 36rem;
    }
    .extraImages-wrapper {
        width: 36rem;
    }
    .extraModal-description {
        font-size: 1.4rem;
    }
    .extraYard h1 {
        font-size: 2.35rem;
    }
    .extraImages-wrapper img {
        width: 35rem;
    }
}