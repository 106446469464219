.about-container {
    padding: 2.5rem 0 5rem 20rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* LEFT SIDE */
.about-left-box {
    padding-left: 5.25rem;
}
.about-left-title {
    position: relative;
    font-size: 6.5rem;
    line-height: 8.25rem;
}
.about-left-title span {
    display: inline-block;
    transition: color .35s ease;
}
.about-left-title span:hover {
    color: #53a7d1;
}
@keyframes aboutFadeIn {
    from {transform: scale3d(1, 1, 1); opacity: 0;}
    30% {transform: scale3d(1.25, 0.75, 1)}
    40% {transform: scale3d(0.75, 1.2, 1)}
    50% {transform: scale3d(1.15, 0.85, 1); opacity: 1;}
    65% {transform: scale3d(.95, 1.05, 1)}
    75% {transform: scale3d(1.05, .95, 1)}
    to {transform: scale3d(1, 1, 1); opacity: 1;}
}
#letter_H {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 0s;}
#letter_e {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .05s;}
#letter_l {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .1s;}
#letter_l_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .15s;}
#letter_o {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .2s;}
#letter_comma {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .25s;}
#letter_I {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .3s;}
#letter_hyphen {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .35s;}
#letter_m {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .4s;}
#letter_J {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .45s;}
#letter_o_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .5s;}
#letter_s {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .55s;}
#letter_h {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .6s;}
#letter_u {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .65s;}
#letter_a {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .7s;}
#letter_comma_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .75s;}
#letter_P {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.3s;}
#letter_r {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.35s;}
#letter_e_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.4s;}
#letter_hyphen_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.45s;}
#letter_M_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.5s;}
#letter_e_three {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.55s;}
#letter_d {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.65s;}
#letter_U_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.7s;}
#letter_n {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.75s;}
#letter_d_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.8s;}
#letter_e_four {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.85s;}
#letter_r_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 1.9s;}
#letter_g {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 2s;}
#letter_r_three {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 2.05s;}
#letter_a_two {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 2.1s;}
#letter_d_three {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: 2.15s;}
@keyframes aboutTitleAfterFade {
    100% {opacity: 1}
}
@-webkit-keyframes aboutTitleAfterFade {
    100% {opacity: 1}
}
@keyframes aboutDescriptionFade {
    100% {opacity: 1}
}
@-webkit-keyframes aboutDescriptionFade {
    100% {opacity: 1}
}
.about-left-description {
    position: relative;
    color: #8d8d8d;
    font-size: 1.65rem;
    letter-spacing: 4px;
    margin-top: 3.85rem;
    animation: aboutDescriptionFade 1.85s ease .75s forwards;
    opacity: 0;
}
@keyframes aboutButtonBoxUp {
    100% {margin-top: 6.5rem; margin-bottom: 0; opacity: 1}
}
@-webkit-keyframes aboutButtonBoxUp {
    100% {margin-top: 6.5rem; margin-bottom: 0; opacity: 1}
}
.about-left-button-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12rem;
    margin-bottom: -5.5rem;
    opacity: 0;
    animation: aboutButtonBoxUp 1s ease 1.5s forwards;
}
.about-contact-button {
    position: relative;
    display: block;
    margin-top: -1.75rem;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 4px;
    height: 51.5px;
    width: 215px;
    background-color: transparent;
    border: 1px solid #53a7d1;
    color: #53a7d1;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s ease-out all;
}
.about-contact-button:hover {
    color: #1f1f1f;
}
.about-contact-button:after {
    background: #53a7d1;
    content: "";
    height: 220px;
    left: -350px;
    position: absolute;
    top: -100px;
    transform: rotate(-45deg);
    transition: all .8s cubic-bezier(0.19, 1, 0.22, 1);
    width: 350px;
    z-index: -10;
}
.about-contact-button:hover:after {
    left: -50px;
}
.about-resume-box {
    margin-left: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: -1.75rem;
}
.about-resume-title {
    font-size: 2.085rem;
    font-weight: 400;
    font-style: italic;
    color: #fff;
    text-align: center;
}
.about-resume-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}
.about-resume-pdf {
    height: 5.4rem;
    width: 5.4rem;
    margin-top: .2rem;
    transition: all ease .15s;
}
.about-resume-pdf:hover {
    cursor: pointer;
    transform: scale(1.085);
}
.about-resume-pdf:active {
    transform: scale(.95);
}

/* MIDDLE */
.about-middle-separator {
    height: 0;
    width: 1px;
    margin-top: 2.5rem;
    margin-left: .75rem;
    box-shadow: rgba(15, 15, 15, .65) 0px 2px 4px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255), rgba(255, 255, 255, 0));
    opacity: .35;
    border-radius: 1rem;
    animation: middleAboutAcross 4s ease .5s forwards;
}
@keyframes middleAboutAcross {
    100% {height: 85vh}
}


/* RIGHT SIDE */
@keyframes aboutRightSideFade {
    100% {opacity: 1}
}
@-webkit-keyframes aboutRightSideFade {
    100% {opacity: 1}
}
.about-right-box {
    padding-right: 7rem;
    width: 45%;
    animation: aboutRightSideFade 1.5s ease 2s forwards;
    opacity: 0;
}
.about-right-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #53a7d1;
    font-size: 5.35rem;
    line-height: 10rem;
    font-weight: 500;
}
.about-right-title img {
    max-height: 27.5rem;
    border-radius: 5rem;
    border: 5px solid #356e8a;
    margin-bottom: 1.5rem;
}
.about-right-description {
    margin-top: 2rem;
    position: relative;
    font-size: 1.85rem;
    line-height: 1.55;
    text-align: center;
}
.about-right-description span {
    text-decoration: underline;
    font-weight: 500;
    color: #53a7d1;
    cursor: pointer;
}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .about-left-title {
        font-size: 7rem;
        line-height: 8.25rem;
    }
}
@media (max-width: 1300px) {
    .about-left-title {
        font-size: 6.5rem;
        line-height: 7.5rem;
    }
    .about-right-title {
        font-size: 5rem;
        line-height: 12rem;
    }
        .about-right-box {
        padding-right: 5rem;
    }
}
@media (max-width: 1250px) {
    .about-container {
        padding: 2.5rem 0 5rem 17rem;
    }
    .about-left-title {
        font-size: 6rem;
    }
    .about-left-box {
        padding-left: 4rem;
    }
}
@media (max-width: 1200px) {
    .about-left-title {
        font-size: 5.5rem;
        line-height: 7rem;
    }
    .about-right-title {
        font-size: 4.5rem;
        line-height: 11.5rem;
    }
    .about-right-description {
        font-size: 1.55rem;
    }
    .about-contact-button {
        font-size: 14px;
        line-height: 48.5px;
        height: 48.5px;
        width: 180px;
    }
    .about-resume-box {
        gap: 1rem;
    }
    .about-resume-title {
        font-size: 1.85rem;
    }
    .about-resume-pdf {
        height: 4.75rem;
        width: 4.75rem;
    }
    .about-resume-googleDocs {
        height: 4.6rem;
        width: 4.6rem;
    }
    .about-resume-buttons-wrapper {
        gap: .25rem;
    }
    .about-left-description {
        font-size: 1.5rem;
    }
    .about-right-box {
        padding-right: 2.5rem;
    }
}
@media (max-width: 1100px) {
    .about-container {
        padding: 2.5rem 0 5rem 2rem;
    }
    .about-right-box {
        padding-right: 5rem;
    }
}
@media (max-width: 1000px) {
    .about-container {
        padding: 2.5rem 0 5rem 0;
    }
    .about-right-box {
        padding-right: 3rem;
    }
}
@media (max-width: 950px) {
    .about-left-title {
        font-size: 6.5rem;
        line-height: 7.5rem;
    }
    .about-right-title {
        font-size: 5rem;
        line-height: 12rem;
    }
    .about-container {
        flex-direction: column;
    }
    .about-left-box {
        padding-left: 0rem;
        margin: 5rem 0;
    }
    .about-right-box {
        padding-right: 0rem;
        width: 60rem;
        margin: 0 0 2.5rem;
    }
    .about-right-description {
        font-size: 1.8rem;
    }
    .about-middle-separator {
        height: 2px;
        width: 0;
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 5rem;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255), rgba(255, 255, 255, 0));
    }
    @keyframes middleAboutAcross {
        100% {width: 80vw}
    }
}
@media (max-width: 650px) {
    .about-resume-box {
        margin-left: 3rem;
        gap: .75rem;
    }
    .about-container {
        min-height: 0;
    }
    .about-left-title {
        font-size: 5rem;
        line-height: 6.25rem;
    }
    .about-left-description {
        font-size: 1.5rem;
    }
    .about-right-title {
        font-size: 4rem;
        line-height: 10rem;
    }
    .about-right-description {
        font-size: 1.4rem;
        width: 80%;
        margin-left: 10%;
    }
    .about-contact-button {
        font-size: 12px;
        line-height: 45px;
        height: 45px;
        width: 165px;
    }
    .about-resume-title {
        font-size: 1.65rem;
    }
    .about-resume-pdf {
        height: 4.35rem;
        width: 4.35rem;
    }
    .about-resume-googleDocs {
        height: 4.25rem;
        width: 4.25rem;
    }
    .about-resume-buttons-wrapper {
        gap: -1rem;
    }
    .about-right-title:before {
        margin-top: -3rem;
    }
    .about-right-title:after {
        bottom: -3.25rem;
    }
    .about-right-title img {
        max-height: 21.5rem;
        border-radius: 2.5rem;
    }
}
@media (max-width: 480px) {
    .about-left-title:before {
        margin-top: -3.35rem;
    }
    .about-left-title {
        font-size: 4.125rem;
        line-height: 5.125rem;
    }
    .about-left-title:after {
        margin-top: 1.25rem;
        margin-left: .75rem;
    }
    .about-left-description {
        font-size: 1.35rem;
        letter-spacing: 2px;
    }
    .about-right-box {
        width: 30rem;
    }
    .about-contact-button {
        font-size: 12px;
        line-height: 42px;
        letter-spacing: 2px;
        height: 43px;
        width: 140px;
    }
    .about-resume-box {
        margin-left: 2rem;
        gap: .5rem;
    }
    .about-resume-title {
        font-size: 1.5rem;
    }
    .about-resume-pdf {
        height: 4rem;
        width: 4rem;
        margin-top: .2rem;
    }
    .about-resume-googleDocs {
        height: 3.75rem;
        width: 3.75rem;
    }
}
@media (max-width: 390px) {
    .about-left-title:before {
        margin-top: -3.125rem;
    }
    .about-left-title {
        font-size: 3.75rem;
        line-height: 4.5rem;
    }
    .about-left-title:after {
        margin-top: 1.35rem;
        margin-left: .65rem;
    }
    .about-left-description {
        font-size: 1.25rem;
    }
    .about-contact-button {
        font-size: 12px;
        line-height: 38px;
        height: 39px;
        width: 125px;
    }
    .about-resume-box {
        margin-left: 1.65rem;
        gap: .5rem;
    }
    .about-resume-title {
        font-size: 1.35rem;
    }
    .about-resume-pdf {
        height: 3.65rem;
        width: 3.65rem;
    }
    .about-resume-googleDocs {
        height: 3.4rem;
        width: 3.4rem;
    }
}