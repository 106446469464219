.skills-container {
    padding: 3.5rem 0 3.5rem 18.5rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
}
.skills-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
.skills-header {
    position: relative;
    color: white;
    font-size: 6.25rem;
    font-weight: 600;
    -webkit-animation: skilsHeadFade 2s ease forwards;
    -moz-animation: skilsHeadFade 2s ease forwards;
    -ms-animation: skilsHeadFade 2s ease forwards;
    -o-animation: skilsHeadFade 2s ease forwards;
    animation: skilsHeadFade 2s ease forwards;
    margin-top: 3.5rem;
    margin-bottom: -3.5rem;
    opacity: 0;
}
.skills-header span {
    display: inline-block;
    transition: color .35s ease;
}
.skills-header span:hover {
    color: #53a7d1;
    -webkit-animation: contactBounce 1s ease forwards;
    -moz-animation: contactBounce 1s ease forwards;
    -ms-animation: contactBounce 1s ease forwards;
    -o-animation: contactBounce 1s ease forwards;
    animation: contactBounce 1s ease forwards;
}
.skills-description {
    position: relative;
    font-family: monospace;
    display: flex;
    align-items: flex-start;
    font-size: .8rem;
    width: 82.25rem;
    height: 2.5rem;
    margin-top: 2rem;
}
.skills-box:first-of-type {
    margin-top: 7.5rem;
}
@keyframes skilsHeadFade {
    10% {opacity: 0}
    50% {margin-top: 0rem; margin-bottom: 0rem}
    100% {opacity: 1; margin-top: 0rem; margin-bottom: 0rem}
}
@keyframes skilsBoxFade {
    100% {opacity: 1}
}
.skills-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 5.5rem 0 0;
    padding: 2.5rem 0;
    border: 1px solid #53a7d1;
    background-color: rgba(0, 0, 0, .25);
    color: whitesmoke;
    border-radius: 1rem;
    -webkit-animation: skilsBoxFade 1.75s ease forwards;
    -moz-animation: skilsBoxFade 1.75s ease forwards;
    -ms-animation: skilsBoxFade 1.75s ease forwards;
    -o-animation: skilsBoxFade 1.75s ease forwards;
    animation: skilsBoxFade 1.75s ease forwards;
    opacity: 0;
}
.skills-title {
    position: absolute;
    color: black;
    text-transform: uppercase;
    border: 2px solid #53a7d1;
    background-color: #53a7d1;
    top: -3.25rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    font-weight: 900;
    letter-spacing: 1px;
    padding: .5rem 1.65rem;
    border-radius: .5rem .5rem 0 0;
    -webkit-animation: skilsBoxFade 2s ease forwards;
    -moz-animation: skilsBoxFade 2s ease forwards;
    -ms-animation: skilsBoxFade 2s ease forwards;
    -o-animation: skilsBoxFade 2s ease forwards;
    animation: skilsBoxFade 2s ease forwards;
    opacity: 0;
}
.skills-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}
.skillSeparator {
    height: 13.5rem;
    width: 2px;
    border-radius: 1rem;
    background-color: #515152;
}
.skills-item {
    height: 17.5rem;
    width: 22.5rem;
    padding: 0 1rem;
    margin-top: -.75rem;
    margin-bottom: .75rem;
    text-align: center;
}
.skills-item img {
    height: 8.5rem;
    width: 8.5rem;
    transition: all .5s ease-in-out;
}
.skills-item img:hover {
    transform: rotate(360deg) scale(1.15);
}
.skills-item h2 {
    font-size: 2.5rem;
    padding: 1.25rem 0 .65rem;
}
.skills-item p {
    font-size: 1.35rem;
    line-height: 1.4;
}
#ReactSkill {animation: skilsBoxFade 1s ease .75s forwards; opacity: 0}
#ReduxSkill {animation: skilsBoxFade 1s ease 1.25s forwards; opacity: 0}
#JavascriptSkill {animation: skilsBoxFade 1s ease 1.75s forwards; opacity: 0}
#RubySkill {animation: skilsBoxFade 1s ease 2.25s forwards; opacity: 0}
#CSSSkill {animation: skilsBoxFade 1s ease 2.75s forwards; opacity: 0}
#NodeSkill {animation: skilsBoxFade 1s ease .75s forwards; opacity: 0}
#MongoDBSkill {animation: skilsBoxFade 1s ease 1.25s forwards; opacity: 0}
#SQLSkill {animation: skilsBoxFade 1s ease 1.75s forwards; opacity: 0}
#RubyonRailsSkill {animation: skilsBoxFade 1s ease 2.25s forwards; opacity: 0}
#FirebaseSkill {animation: skilsBoxFade 1s ease 2.75s forwards; opacity: 0}

.education-separator {
    width: 0;
    height: 1px;
    box-shadow: rgba(15, 15, 15, .65) 0px 2px 4px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255), rgba(255, 255, 255, 0));
    opacity: .8;
    border-radius: 1rem;
    margin: 7.5rem 0 5rem;
}
#education-separator-active {
    -webkit-animation: lineAcross 4s ease forwards;
    -moz-animation: lineAcross 4s ease forwards;
    -ms-animation: lineAcross 4s ease forwards;
    -o-animation: lineAcross 4s ease forwards;
    animation: lineAcross 4s ease forwards;
}
@keyframes lineAcross {
    100% {width: 90%}
}
@-webkit-keyframes lineAcross {
    100% {width: 90%}
}

.education-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
.education-header {
    position: relative;
    color: white;
    font-size: 6rem;
    font-weight: 600;
    margin-top: 5rem;
    margin-bottom: -5rem;
    opacity: 0;
}
.education-header span {
    display: inline-block;
    transition: color .35s ease;
}
.education-header span:hover {
    color: #53a7d1;
    animation: contactBounce 1s ease forwards;
}
#certTitle-active {
    animation: skilsHeadFade 2s ease forwards;
}

/* FLATIRON */
.flatiron-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: flex-start;
    margin-top: 2.5rem;
    width: 100%;
    height: 35rem;
    box-shadow: rgba(0, 0, 0, 0.325) 4px 4px 2px;
    margin-left: 25rem;
    opacity: 0;
}
#cert1-active {
    animation: certItemSlide 2s ease forwards;
}
@keyframes certItemSlide {
    100% {opacity: 1; margin-left: 0}
}
@-webkit-keyframes certItemSlide {
    100% {opacity: 1; margin-left: 0}
}
.flatiron-img-wrapper {
    position: relative;
    display: flex;
    height: 100%;
    width: 42.5rem;
}
.flatiron-images {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: .25rem 0 0 .25rem;
}
.flatiron-img {
    position: absolute;
    left: 0;
    height: 100%;
    width: 42.5rem;
    border-radius: .25rem 0 0 .25rem;
    transition: opacity .5s ease-in-out;
}
#flatiron-pointer {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 2.25rem;
    color: whitesmoke;
    opacity: 1;
    transition: all .25s ease;
}
#flatiron-pointer-gone {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 2.25rem;
    color: whitesmoke;
    opacity: 0;
    transition: all .25s ease;
}
.flatiron-img-hover-box {
    position: absolute;
    display: -ms-grid;
    display: grid;
    place-content: center;
    height: 100%;
    width: 42.5rem;
    background-color: rgba(50, 50, 50, .85);
    opacity: 0;
    cursor: pointer;
    transition: all .25s ease;
}
.flatiron-img-hover-box h1 {
    position: absolute;
    font-size: 2.5rem;
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .5s ease-in-out;
}
.flatiron-img-hover-box:hover {
    opacity: 1;
}
.flatiron-text-box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 42.5rem);
    padding: 0 2.5rem;
    background-color: #111;
    border-radius: .25rem;
}
.flatiron-text-box h3 {
    align-self: center;
    font-size: 1.6rem;
    font-weight: 300;
    text-transform: uppercase;
}
.flatiron-text-box h1 {
    align-self: center;
    font-size: 3.5rem;
    font-weight: 500;
    padding-bottom: .25rem;
}
.flatiron-text-box h4 {
    align-self: center;
    font-size: 2.125rem;
    padding-bottom: .75rem;
}
.flatiron-text-box p {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.25rem;
    font-style: italic;
}
.flatiron-description-box ul {
    padding: .5rem 0 1rem;
    padding-left: 2.5rem;
    font-size: 1.65rem;
}
.flatiron-description-box p {
    padding-top: 1rem;
    font-size: 1.6rem;
    font-weight: 900;
    font-style: normal;
}

/* CERTS */
.cert-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2.5rem;
    width: 90%;
    gap: 2rem;
}
.cert-img-wrapper {
    position: relative;
    display: flex;
}
.cert-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #111;
    height: 100%;
    width: 100%;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.325) 4px 4px 2px;
    margin-left: 25rem;
    opacity: 0;
}
#cert2-active,
#cert3-active,
#cert4-active {
    animation: certItemSlide 3s ease forwards;
}
.cert-img-hover-box {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(50, 50, 50, .85);
    opacity: 0;
    cursor: pointer;
    transition: all .25s ease;
}
.cert-img-hover-box h1 {
    font-size: 1.85rem;
    color: white;
    padding-right: .75rem;
}
#cert-arrow {
    font-size: 1.75rem;
    color: white;
}
.cert-img-hover-box:hover {
    opacity: 1;
}
.cert-img {
    height: 20rem;
    width: auto;
    border-radius: .5rem 0 0 .5rem;
}
#cert-pointer {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 2.25rem;
    color: whitesmoke;
    opacity: 1;
    transition: all .25s ease;
}
#cert-pointer-gone {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 2.25rem;
    color: whitesmoke;
    opacity: 0;
    transition: all .25s ease;
}
.cert-text-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1rem;
}
.cert-title {
    font-size: 3.25rem;
    font-weight: 500;
    margin-bottom: 1.65rem;
}
.cert-text-box p {
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 2.25rem;
    width: 90%;
}
.cert-text-box p a {
    color: #53a7d1;
    text-decoration: underline;
}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .skills-box {
        width: 95%;
    }
    .skills-item h2 {
        font-size: 2.25rem;
        padding: 1.25rem 0 .65rem;
    }
}
@media (max-width: 1350px) {
    .skills-item {
        height: 17.5rem;
        width: 20rem;
        padding: 0 1rem;
    }
    .skills-item img {
        height: 7rem;
        width: 7rem;
    }
    .skills-item p {
        font-size: 1.275rem;
        line-height: 1.45;
    }
}
@media (max-width: 1300px) {
    .cert-items {
        padding-top: 2rem;
        width: 96%;
    }
    .flatiron-text-box p {
        font-size: 1.75rem;
        line-height: 2.175rem;
    }
}
@media (max-width: 1250px) {
    .skills-description {
        font-size: .75rem;
        width: 77rem;
    }
    .skills-container {
        padding: 3.5rem 0 3.5rem 16.5rem;
    }
    .skills-item {
        height: 16.5rem;
        width: 18.5rem;
        padding: 0 .85rem;
    }
    .skills-item img {
        height: 6.25rem;
        width: 6.25rem;
    }
}
@media (max-width: 1200px) {
    .cert-items {
        width: 97.5%;
    }
    .flatiron-text-box p {
        font-size: 1.5rem;
        line-height: 1.45;
    }
    .flatiron-description-box ul {
        font-size: 1.55rem;
    }
}
@media (max-width: 1150px) {
    .skills-box {
        width: 97.5%;
    }
    .skills-item {
        height: 16.5rem;
        width: 18rem;
        padding: 0 .5rem;
    }
    .skills-item h2 {
        font-size: 2.25rem;
    }
    .skills-item p {
        font-size: 1.2rem;
        line-height: 1.45;
    }
    .flatiron-description-box ul {
        font-size: 1.45rem;
    }
}
@media (max-width: 1100px) {
    .skills-container {
        padding: 3.5rem 0 3.5rem 0;
    }
    .skills-box {
        width: 95%;
    }
    .skills-item img {
        height: 5.85rem;
        width: 5.85rem;
    }
    .skills-item {
        height: 15.5rem;
    }
    .skills-item h2 {
        padding: 1rem 0 .75rem;
    }
    .cert-items {
        width: 95%;
    }
    .flatiron-description-box ul {
        font-size: 1.4rem;
    }
}
@media (max-width: 1000px) {
    .skillSeparator {
        height: 85%;
    }
}
@media (max-width: 950px) {
    .cert-title {
        font-size: 3rem;
    }
    .skills-header {
        font-size: 5.5rem;
    }
    .education-header {
        font-size: 5rem;
    }
    .flatiron-item {
        flex-direction: column;
        width: 100%;
        height: 57.5rem;
        background-color: #fff;
        border-radius: 0 0 .5rem .5rem;
    }
    .flatiron-img-wrapper {
        height: 25rem;
        width: 35rem;
        justify-content: center;
    }
    .flatiron-img-hover-box {
        width: 35rem;
    }
    .flatiron-img {
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 35rem;
    }
    .flatiron-text-box {
        height: 32.5rem;
        width: 100%;
        padding: 0 2rem;
    }
    .skills-description {
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: -moz-fit-content;
        width: fit-content;
        height: 5.5rem;
        margin-top: 2rem;
        font-size: .85rem;
        line-height: 1.25;
    }
    .skills-content {
        grid-template-columns: auto 10px auto 10px auto;
        grid-template-rows: auto auto;
        justify-content: center;
    }
    .skills-item {
        height: 18.5rem;
        width: 18rem;
        padding: 0 .75rem;
    }
    .responsiveSkillsItem1 {
        position: absolute;
        bottom: 0;
        left: 11rem;
    }
    .responsiveSkillsItem2 {
        position: absolute;
        bottom: 0;
        left: 30rem;
        height: 12.025rem !important;
        margin-bottom: 5.5rem !important;
    }
    .responsiveSkillsItem3 {
        position: absolute;
        bottom: 0;
        right: 11rem;
    }
    .skillSeparator {
        height: 65%;
        width: 3px;
        margin: 0 auto;
    }
    .hiddenSkillSeparator {
        display: none;
    }
    .skills-item h2 {
        font-size: 2.25rem;
    }
    .skills-item p {
        font-size: 1.25rem;
        line-height: 1.4;
    }
    .skills-box {
        width: 60rem;
        height: 40rem;
        justify-content: flex-start;
    }
}
@media (max-width: 800px) {
    .education-header {
        font-size: 4.5rem;
    }
    .flatiron-text-box p {
        line-height: 1.85rem;
    }
}
@media (max-width: 650px) {
    .skills-container {
        padding: 2rem 0 2rem 0;
    }
    .skills-header {
        font-size: 4.5rem;
    }
    .education-header {
        font-size: 3.5rem;
    }
    .skills-description {
        font-size: .65rem;
        word-spacing: -1px;
        line-height: 1.35;
    }
    .skills-content {
        display: grid;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        grid-template-columns: auto;
        grid-template-rows: auto;
    }
    .skills-item {
        height: 11.25rem;
        width: 25rem;
        padding: 0;
    }
    .responsiveSkillsItem1 {
        position: relative;
        left: 0;
    }
    .responsiveSkillsItem3 {
        position: relative;
        right: 0;
    }
    .skillSeparator {
        display: none;
    }
    .skills-item img {
        height: 3.5rem;
        width: 3.5rem;
    }
    .skills-item h2 {
        font-size: 2.25rem;
        padding: 0 0 .25rem;
    }
    .skills-item p {
        font-size: 1.25rem;
        line-height: 1.4;
    }
    .skills-box {
        padding: 2rem 0;
        width: 30rem;
        height: 59.25rem;
        justify-content: flex-start;
    }
    .skills-box:first-of-type {
        margin-top: 5rem;
    }
    .cert-title {
        font-size: 2.25rem;
        margin-bottom: 1rem;
    }
    .cert-text-box p {
        font-size: 1.35rem;
        line-height: 1.35;
        width: 95%;
    }
    #cert-pointer-gone {
        opacity: 1;
    }
    .flatiron-text-box h3 {
        font-size: 1.5rem;
    }
    .flatiron-text-box h1 {
        font-size: 2.85rem;
    }
    .flatiron-text-box h4 {
        font-size: 1.85rem;
    }
    .flatiron-text-box p {
        font-size: 1.5rem;
        line-height: 1.85rem;
    }
}
@media (max-width: 480px) {
    .skills-header {
        font-size: 4rem;
    }
    .education-header {
        font-size: 2.6rem;
    }
    .skills-description {
        font-size: .55rem;
        word-spacing: -2px;
        line-height: 1.35;
    }
    .flatiron-description-box ul {
        font-size: 1.2rem;
    }
    .flatiron-text-box p {
        font-size: 1.25rem;
        line-height: 1.85rem;
    }
    .cert-title {
        font-size: 2rem;
        line-height: 1.15;
        margin-bottom: .75rem;
    }
    .cert-text-box p {
        font-size: 1.25rem;
        line-height: 1.3;
        width: 98.5%;
    }
    .cert-img {
        height: 12.5rem;
        width: 12.5rem;
        border-radius: .5rem;
    }
    .cert-img-hover-box h1 {
        font-size: 1.25rem;
    }
    #cert-arrow {
        font-size: 1.25rem;
    }
    .cert-item {
        height: 20rem;
    }
    .flatiron-item {
        margin-top: 1.25rem;
    }
    .education-separator {
        margin: 4.5rem 0 3.5rem;
    }
    #skillsMobileSecond {
        opacity: 0;
        animation: visibleSecondSkillMobile .25s ease 3.75s forwards;
    }
    @keyframes visibleSecondSkillMobile {
        100% {opacity: 1}
    }
    @-webkit-keyframes visibleSecondSkillMobile {
        100% {opacity: 1}
    }
    .note-wrapper p {
        font-size: 1.5rem;
    }
}
@media (max-width: 380px) {
    .flatiron-description-box ul {
        font-size: 1.075rem;
    }
    .flatiron-description-box p {
        padding-top: .85rem;
    }
    .flatiron-text-box p {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
@media (max-width: 360px) {
    .education-header {
        font-size: 2.25rem;
    }
    .flatiron-img-wrapper {
        width: 30rem;
    }
    .flatiron-img-hover-box {
        width: 30rem;
    }
    .skills-description {
        font-size: .5rem;
        word-spacing: -2px;
        line-height: 1.35;
    }
    .flatiron-img {
        width: 30rem;
    }
    .flatiron-text-box {
        height: 33.5rem;
        padding: 0 1rem;
    }
    .flatiron-description-box ul {
        font-size: 1rem;
    }
    .flatiron-text-box h1 {
        font-size: 2.65rem;
    }
    .cert-img {
        height: 10rem;
        width: 10rem;
    }
    .cert-img-hover-box h1 {
        font-size: 1rem;
    }
    #cert-arrow {
        font-size: 1rem;
    }
}