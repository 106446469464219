.algo-container {
    padding: 3.5rem 0 3.5rem 18.5rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.algo-header {
    position: relative;
    color: white;
    font-size: 6.25rem;
    font-weight: 600;
    animation: skilsHeadFade 2s ease forwards;
    margin-top: 3.5rem;
    margin-bottom: -3.5rem;
    opacity: 0;
}
.algo-header span {
    display: inline-block;
    transition: color .35s ease;
}
.algo-header span:hover {
    color: #53a7d1;
    animation: contactBounce 1s ease forwards
}
.algo-description {
    margin-top: 2.75rem;
    font-size: 1.85rem;
}
#algo-slide1,
#algo-slide2 {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
#algo-slide1 {
    opacity: 0;
    animation: showup 2s ease 1.5s forwards;
}
#algo-slide2 {
    width: 0;
    animation: reveal 2.5s ease 1.5s forwards;
}
#algo-child-slide {
    margin-left: -340px;
    animation: slidein 2.5s ease 1.5s forwards;
}
#ralph_waldo_emerson {
    opacity: 0;
    animation: showup 2s ease 4.25s forwards;
}
@keyframes showup {
    0% {opacity: 0}
    100% {opacity: 1}
}
@keyframes slidein {
    0% {margin-left: -800px}
    50% {margin-left: -800px}
    100% {margin-left: 0px}
}
@keyframes reveal {
    0% {opacity: 0; width: 0px}
    50% {opacity: 1; width: 0px}
    100% {width: 620px}
}
.algo-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: center;
    width: 85%;
    gap: 4.5rem;
    margin-top: 3.85rem;
}
.algo-item {
    padding: 0 1rem;
    width: 54rem;
    margin: 0 auto;
    background-color: #1a1a1a;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    margin-top: 10rem;
    margin-bottom: -10rem;
    opacity: 0;
}
@keyframes algoFadeUp {
    100% {opacity: 1; margin-top: 0; margin-bottom: 0}
}
.algo-item1 {animation: algoFadeUp 1.25s ease .5s forwards}
.algo-item2 {animation: algoFadeUp 1.25s ease .75s forwards}
.algo-item3 {animation: algoFadeUp 1.25s ease 1s forwards}
.algo-item4 {animation: algoFadeUp 1.25s ease 1.25s forwards}
#algoJavascript::before {
    content: "";
    display: block;
    height: 2px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    transition: all .7s ease;
    background-color: #fd2154
}
#algoRuby::before {
    content: "";
    display: block;
    height: 2px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    transition: all .7s ease;
    background-color: orange
}
#algoNotJavascript::before {
    content: "";
    display: block;
    height: 2px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    transition: all .7s ease;
    background: #58cef9;
}
#algoDefNotJavascript::before {
    content: "";
    display: block;
    height: 2px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    transition: all .7s ease;
    background-color: #00ff80;
}
.algo-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.algo-item p:first-child {
    display: block;
    font-size: 16px;
    padding: 1.5rem 2rem 2rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    border: 0;
    margin-top: 1rem;
}
#algoJavascript p:first-child {
    color: #fd2154;
}
#algoRuby p:first-child {
    color: orange;
}
#algoNotJavascript p:first-child {
    color: #58cef9;
}
#algoDefNotJavascript p:first-child {
    color: #00ff80;
}
.algo-item p:last-child {
    display: block;
    font-size: 14px;
    padding: 1.5rem 2rem 2rem;
    color: gray;
    text-transform: uppercase;
    letter-spacing: 3px;
    border: 0;
    margin-top: 1rem;
}
.algo-iframe-wrapper {
    width: 52rem;
    height: 30rem;
    margin-bottom: 1rem;
}
.extracurricular-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: .5rem;
}
.extracurricular-item {
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    padding: 0 2rem;
    margin: .25rem 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: #222;
}
#extracurricularTabNumTwo {
    margin: .5rem 0;
}
#extracurricularTabNumTwo h2 {
    font-size: 2.125rem;
}
#extracurricularTabNumTwo img {
    height: 5rem;
    width: auto;
}
#extracurricularTabNumTwo #extracurricular-tab {
    font-size: 2.5rem;
}
.extracurricularImage {
    height: 4rem;
    width: auto;
}
.extracurricular-item h2 {
    font-size: 1.8rem;
    font-weight: 300;
    color: whitesmoke;
}
#extracurricular-tab {
    font-size: 2rem;
}
.extracurricular-item:hover {
    cursor: pointer;
    border-color: #888;
    box-shadow: 0 0 4px #888;
}
.extracurricular-item:hover .extracurricularImage {
    animation: extraSpin 4s ease infinite;
}
.extracurricular-item:hover #extracurricular-tab {
    animation: extraBounce 2.5s ease-in-out infinite;
}
@keyframes extraSpin {
    from {transform: rotateY(360deg)}
    to {transform: rotateY(0deg)}
}
@keyframes extraBounce {
    0%, 60%, 80%, 100% {transform: translateX(0);} 
    20% {transform: translateX(-.7rem);} 
    40% {transform: translateX(.375rem);}
} 
#extracurricularIcon1 {height: 4.35rem;}
#extracurricularIcon2 {height: 4.25rem; margin-left: .5rem;}
#extracurricularIcon3 {height: 4rem;}
#extracurricularIcon4 {height: 4.25rem;}
#extracurricularIcon5 {height: 4.85rem;}
#extracurricularIcon6 {height: 4.85rem;}
#extracurricularIcon7 {height: 4rem;}
#extracurricularIcon8 {height: 4.75rem; margin-bottom: .5rem;}
#extracurricularIcon9 {height: 4.85rem !important;}
#extracurricularIcon10 {height: 5.75rem !important;}
#extracurricularIcon11 {height: 5.5rem !important; margin-left: .75rem;}
#extracurricularIcon12 {height: 5.25rem !important; margin-left: .35rem;}

#extracurricularText1 {margin-left: -1rem;}
#extracurricularText2 {margin-left: -.5rem;}
#extracurricularText3 {margin-left: -2rem;}
#extracurricularText4 {margin-left: -.5rem;}
#extracurricularText5 {margin-left: 0rem;}
#extracurricularText6 {margin-left: 0rem;}
#extracurricularText7 {margin-left: -.35rem;}
#extracurricularText8 {margin-left: -1.5rem;}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .algo-wrapper {
        width: 90%;
        gap: 4rem;
    }
    .algo-item {
        width: 52rem;
    }
    .algo-iframe-wrapper {
        width: 50rem;
        height: 30rem;
    }
}
@media (max-width: 1350px) {
    .algo-wrapper {
        width: 90%;
        gap: 4rem;
    }
    .algo-item {
        width: 48rem;
    }
    .algo-iframe-wrapper {
        width: 46rem;
        height: 28.5rem;
    }
}
@media (max-width: 1250px) {
    .algo-container {
        padding: 3.5rem 0 3.5rem 16.5rem;
    }
    .algo-wrapper {
        width: 95%;
        gap: 3rem;
    }
    .algo-item {
        width: 44rem;
    }
    .algo-iframe-wrapper {
        width: 42rem;
        height: 25.5rem;
    }
    #extracurricularTabNumTwo h2 {
        font-size: 2rem;
    }
    .algo-description {
        font-size: 1.65rem;
    }
    @keyframes reveal {
        0% {opacity: 0; width: 0px}
        50% {opacity: 1; width: 0px}
        100% {width: 560px}
    }
    #extracurricularTabNumTwo {
        margin: .5rem 0;
    }
    #extracurricularTabNumTwo #extracurricular-tab {
        font-size: 2.35rem;
    }
    .extracurricularImage {
        height: 3.5rem !important;
        width: auto;
    }
    .extracurricular-item h2 {
        font-size: 1.75rem;
    }
    #extracurricular-tab {
        font-size: 1.85rem;
    }
}
@media (max-width: 1100px) {
    .algo-header {
        font-size: 5.75rem;
    }
    .algo-container {
        padding: 3.5rem 0 3.5rem 0;
    }
    .algo-wrapper {
        width: 95%;
        gap: 3rem;
    }
    .algo-item {
        width: 40rem;
    }
    .algo-iframe-wrapper {
        width: 38rem;
        height: 21.5rem;
    }
    #extracurricularTabNumTwo h2 {
        font-size: 1.85rem;
    }
    #extracurricularTabNumTwo img {
        height: 4.35rem !important;
        width: auto;
    }
    #extracurricularTabNumTwo #extracurricular-tab {
        font-size: 2.25rem;
    }
}
@media (max-width: 950px) {
    .algo-header {
        font-size: 4.75rem;
    }
    .algo-description {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 2.25rem;
        margin-bottom: 2.25rem;
        font-size: 1.65rem;
        line-height: 2.325rem;
        text-align: center;
        padding: 0 3.25rem;
    }
    .algo-container {
        padding: 2rem 0 2rem 0;
    }
    .algo-wrapper {
        width: 95%;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        gap: 2rem;
        margin-top: 1.25rem;
    }
    #ralph_waldo_emerson {
        animation: showup 2s ease 3.5s forwards;
    }
    #ralph_waldo_emerson::before {
        display: block;
        content: "\a";
        white-space: pre;
        margin-top: -2rem;
    }
    #algo-slide2 {
        opacity: 0;
        width: 400px;
        animation: reveal 2.5s ease 2.5s forwards;
    }
    @keyframes reveal {
        0% {opacity: 0; width: 400px}
        50% {opacity: 1; width: 400px}
        100% {width: 400px; opacity: 1}
    }
    @keyframes slidein {
        0% {margin-left: 0px}
        50% {margin-left: 0px}
        100% {margin-left: 0px}
    }
    #extracurricularTabNumTwo h2 {
        font-size: 1.75rem;
    }
    #extracurricularTabNumTwo img {
        height: 4.25rem !important;
        width: auto;
    }
}
@media (max-width: 650px) {
    .algo-header {
        font-size: 4.25rem;
    }
}
@media (max-width: 480px) {
    .algo-item {
        width: 36rem;
    }
    .algo-iframe-wrapper {
        width: 34rem;
        height: 20rem;
    }
    .algo-description {
        font-size: 1.55rem;
        padding: 0;
    }
    #extracurricularTabNumTwo h2 {
        font-size: 1.6rem;
    }
    #extracurricularTabNumTwo img {
        height: 3.75rem !important;
        width: auto;
    }
    #extracurricularTabNumTwo #extracurricular-tab {
        font-size: 2rem;
    }
    .extracurricularImage {
        height: 3.25rem !important;
    }
    .extracurricular-item h2 {
        font-size: 1.6rem;
    }
    #extracurricular-tab {
        font-size: 1.65rem;
    }
    .algo-item p:last-child {
        font-size: 10px;
        letter-spacing: 2px;
    }
    .algo-container {
        justify-content: center;
    }
}