.contact-container {
    margin-top: -2.5rem;
    padding: 3.5rem 0 3.5rem 20rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact-left-box {
    position: relative;
    width: 49%;
}
.contact-title {
    position: relative;
    font-size: 6.25rem;
    line-height: 1.9;
    width: 90%;
    padding-left: 5%;
}
.contact-title span {
    display: inline-block;
    color: #53a7d1;
    transition: color .35s ease;
}
@keyframes contactBounce {
    from {transform: scale3d(1, 1, 1)}
    30% {transform: scale3d(1.25, 0.75, 1)}
    40% {transform: scale3d(0.75, 1.2, 1)}
    50% {transform: scale3d(1.15, 0.85, 1)}
    65% {transform: scale3d(.95, 1.05, 1)}
    75% {transform: scale3d(1.05, .95, 1)}
    to {transform: scale3d(1, 1, 1)}
}

#cLetter_c1 {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .1s}
#cLetter_o {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .2s}
#cLetter_n {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .3s}
#cLetter_t1 {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .4s}
#cLetter_a {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .5s}
#cLetter_c2 {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .6s}
#cLetter_t2 {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .7s}
#cLetter_m {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .8s}
#cLetter_e {opacity: 0; animation: aboutFadeIn 1s ease-in-out forwards; animation-delay: .9s}

.contact-message {
    display: block;
    width: 90%;
    padding-left: 5%;
    min-width: 320px;
    font-size: 1.75rem;
    line-height: 1.65;
}
.contact-form {
    position: relative;
    display: block;
    width: 90%;
    margin-left: 5%;
    padding-top: 4rem;
    min-height: 20rem;
}
.contact-form ul li {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
    display: block;
    clear: both;
    margin-bottom: 10px;
}
.contact-form input[type="text"]::placeholder ,
.contact-form input[type="email"]::placeholder,
.contact-form textarea::placeholder  {
    color: #8d8d8d;
}
.contact-form input[type="text"],
.contact-form input[type="email"] {
    background: #2b2b2b;
    height: 50px;
    font-size: 16px;
    color: whitesmoke;
    padding: 0 20px;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    width: 100%;
}
#contact-half-left {
    float: left;
    width: 49%;
    clear: none;
}
#contact-half-right {
    float: right;
    width: 49%;
    clear: none;
}
#contact-third-child {
    width: 100%;
}
.contact-form textarea {
    font-family: sans-serif;
    width: 100%;
    height: 50px;
    min-width: 100%;
    min-height: 150px;
    max-width: 100%;
    max-height: 450px;
    background: #2b2b2b;
    font-size: 16px;
    color: whitesmoke;
    padding: 20px;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    resize: vertical;
}
.contact-after::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: #53a7d1;
    height: 2px;
    transition: all .3s;
}
.contact-item input:focus + .contact-after::after,
.contact-item textarea:focus + .contact-after::after {
    width: 100%;
} 
.contact-form button {
    position: relative;
    display: block;
    float: right;
    clear: none;
    margin-top: 2rem;
    font-size: 14px;
    letter-spacing: 4px;
    height: 48px;
    width: 200px;
    background-color: transparent;
    border: 1px solid #53a7d1;
    color: #53a7d1;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    transition: 0.4s ease-out all;
}
.contact-form button:hover {
    color: #1f1f1f;
}
.contact-form button:after {
    background: #53a7d1;
    content: "";
    height: 200px;
    left: -325px;
    position: absolute;
    top: -100px;
    transform: rotate(-45deg);
    transition: all .8s cubic-bezier(0.19, 1, 0.22, 1);
    width: 325px;
    z-index: -10;
}
.contact-form button:hover:after {
    left: -50px;
}
.contact-aside {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 5%;
    max-width: 50%;
}
#contact-copy-top {
    font-size: 2rem;
    font-weight: 300;
    color: #616162;
}
.contact-copy-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .65rem;
    margin-top: .65rem;
}
#contact-copy-icon {
    font-size: 2.75rem;
    color: #53a7d1;
    cursor: pointer;
    transition: all .15s;
}
#contact-copy-icon:hover {
    transform: scale(1.125);
}
#contact-copy-icon:active {
    transform: scale(.9);
}
#contact-copy-email {
    font-size: 2rem;
    color: #bbb;
}
.contact-popup {
    position: absolute;
    font-size: 1.5rem;
    font-weight: 500;
    bottom: -2rem;
    left: -1.25rem;
    color: #fff;
    opacity: 0;
}
#contact-popup-show {
    animation: copyPopup 1.25s ease-out;
}
@keyframes copyPopup {
    25% {opacity: 1}
    75% {opacity: 1}
    100% {opacity: 0; bottom: -3rem}
}
.contact-right-box {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 43.5%;
}
@keyframes mapCoverBye {
    100% {width: 0}
}
.contact-right-cover {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #1f1f1f;
    animation: mapCoverBye .85s ease 1s forwards;
}
@keyframes mapBoxPop {
    100% {margin-top: 0; opacity: 1}
}
.map-popup {
    position: absolute;
    background: rgba(0,0,0,0.875);
    top: 3rem;
    left: 3rem;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6;
    z-index: 90;
    padding: 20px;
    color: #fff;
    margin-top: 5rem;
    animation: mapBoxPop 1s ease 2s forwards;
    opacity: 0;
}
.map-wrapper {
    height: 100%;
    width: 100%;
}
.contactForm1 {
    opacity: 0;
    animation: contactFadeIn 1.5s ease .5s forwards;
}
.contactForm2 {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    animation: contactIsLoadedAnim 1s ease forwards;
    animation-delay: .2s;
}
.contactForm3 {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    animation: contactIsLoadedAnim 1s ease forwards;
    animation-delay: .3s;
}
.contactForm4 {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    animation: contactIsLoadedAnim 1s ease forwards;
    animation-delay: .4s;
}
.contactForm5 {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    animation: contactIsLoadedAnim 1s ease forwards;
    animation-delay: .5s;
}
.contactForm6 {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    animation: contactSldRight 1s ease 1.25s forwards;
}
.contactForm7 {
    opacity: 0;
    left: -10rem;
    animation: contactSldLeft 1s ease 2s forwards;
}
@keyframes contactIsLoadedAnim {
    100% {opacity: 1; transform: none}
}
@keyframes contactSldLeft {
    50% {opacity: 0}
    100% {opacity: 1; left: 0}
}
@keyframes contactSldRight {
    50% {opacity: 0}
    100% {opacity: 1; transform: none}
}
@keyframes contactFadeIn {
    100% {opacity: 1}
}
@-webkit-keyframes contactIsLoadedAnim {
    100% {opacity: 1; -webkit-transform: none}
}
@-webkit-keyframes contactSldLeft {
    50% {opacity: 0}
    100% {opacity: 1; left: 0}
}
@-webkit-keyframes contactSldRight {
    50% {opacity: 0}
    100% {opacity: 1; -webkit-transform: none}
}
@-webkit-keyframes contactFadeIn {
    100% {opacity: 1}
}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .map-popup {
        top: 3rem;
        left: 3rem;
    }
    .contact-title {
        padding-left: 3.75%;
    }
    .contact-form {
        margin-left: 3.5%;
    }
    .contact-message {
        padding-left: 4%;
        font-size: 1.7rem;
    }
    .contact-aside {
        margin-left: 4%;
    }
    #contact-copy-top {
        font-size: 1.95rem;
    }
    #contact-copy-email {
        font-size: 1.9rem;
    }
    #contact-copy-icon {
        font-size: 2.65rem;
    }
}
@media (max-width: 1325px) {
    .contact-left-box {
        width: 50%;
    }
    .contact-right-box {
        width: 42.5%;
    }
    .contact-aside {
        margin-left: 3.5%;
    }
}
@media (max-width: 1300px) {
    .contact-left-box {
        width: 50.5%;
    }
    .contact-right-box {
        width: 42%;
    }
    .contact-title {
        font-size: 6rem;
        padding-left: 3.5%;
    }
    .contact-form {
        margin-left: 3.25%;
    }
    .contact-message {
        width: 92.5%;
        padding-left: 3.75%;
        font-size: 1.65rem;
    }
    .contact-copy-box {
        gap: .5rem;
    }
    #contact-copy-top {
        font-size: 1.8rem;
    }
    #contact-copy-email {
        font-size: 1.75rem;
    }
    #contact-copy-icon {
        font-size: 2.5rem;
    }
}
@media (max-width: 1250px) {
    .contact-container {
        padding: 3.5rem 0 3.5rem 16.5rem;
    }
    .contact-title {
        line-height: 1.85;
        width: 93.5%;
        padding-left: 4%;
    }
    .contact-message {
        width: 97%;
        font-size: 1.625rem;
        padding-left: 4%;
    }
    .contact-form {
        width: 93.5%;
        margin-left: 4%;
        padding-top: 3.75rem;
    }
    .contact-aside {
        margin-left: 4%;
    }
}
@media (max-width: 1150px) {
    .contact-message {
        font-size: 1.55rem;
    }
    #contact-copy-top {
        font-size: 1.75rem;
    }
    #contact-copy-email {
        font-size: 1.75rem;
    }
    #contact-copy-icon {
        font-size: 2.5rem;
    }
    .contact-form button {
        font-size: 13px;
        height: 46px;
        width: 185px;
    }
    .contact-aside {
        max-width: 52.5%;
    }
}
@media (max-width: 1125px) {
    #contact-copy-email {
        font-size: 1.7rem;
    }
    .contact-popup {
        font-size: 1.35rem;
    }
}
@media (max-width: 1100px) {
    .contact-container {
        padding: 3.5rem 0 3.5rem 0;
    }
    .contact-left-box {
        width: 53.5%;
    }
    .contact-right-box {
        width: 43.5%;
    }
    .contact-title {
        padding-left: 6.25%;
    }
    .contact-message {
        font-size: 1.65rem;
        padding-left: 6.5%;
    }
    .contact-form {
        margin-left: 6.5%;
    }
    .contact-aside {
        margin-left: 6.5%;
    }
    #contact-copy-top {
        font-size: 1.85rem;
    }
    #contact-copy-email {
        font-size: 1.75rem;
    }
}
@media (max-width: 1000px) {
    .contact-message {
        font-size: 1.625rem;
    }
}
@media (max-width: 950px) {
    .contact-container {
        padding: 3.5rem 0 3.5rem 0;
        flex-direction: column;
    }
    .contact-left-box {
        width: 92.5%;
        margin-bottom: 4.25rem;
    }
    .contact-right-box {
        position: relative;
        height: 50rem;
        width: 92.5%;
    }
    .map-popup {
        top: 2.5rem;
        left: 2.5rem;
    }
    .contact-title {
        font-size: 5.5rem;
        padding-left: 1%;
    }
    .contact-message {
        font-size: 1.75rem;
        padding-left: 1%;
    }
    .contact-form {
        margin-left: 0;
        width: 100%;
    }
    .contact-aside {
        margin-left: 0;
    }
}
@media (max-width: 650px) {
    .contact-title {
        font-size: 5rem;
    }
    .contact-left-box {
        width: 95%;
    }
    .contact-right-box {
        width: 95%;
        height: 45rem;
    }
    .map-popup {
        top: 2rem;
        left: 2rem;
    }
    .contact-message {
        font-size: 1.6rem;
        width: 100%;
        padding-left: 1%;
    }
    #contact-copy-email {
        font-size: 1.675rem;
    }
}
@media (max-width: 480px) {
    .contact-container {
        padding: 3rem 0 3rem 0;
    }
    .contact-title {
        font-size: 4.5rem;
        line-height: 2;
    }
    .contact-message {
        font-size: 1.5rem;
    }
    .map-popup {
        top: 1rem;
        left: 1rem;
        font-size: 16px;
        padding: 16.5px;
    }
    #contact-half-left {
        float: none;
        width: 100%;
    }
    #contact-half-right {
        float: none;
        width: 100%;
    }
    .contact-left-box {
        width: 95%;
        padding-bottom: .5rem;
        margin-bottom: 3rem;
    }
    .contact-form {
        padding-top: 2.85rem;
    }
    .contact-aside {
        position: relative;
        margin-top: 10rem;
        max-width: 100%;
    }
    .contact-form button {
        float: none;
        font-size: 14px;
        letter-spacing: 4px;
        height: 50px;
        width: 210px;
    }
    .contact-copy-box {
        gap: .6rem;
        margin-top: .75rem;
    }
    #contact-copy-top {
        font-size: 1.9rem;
    }
    #contact-copy-email {
        font-size: 1.8rem;
        color: #bbb;
    }
    #contact-copy-icon {
        font-size: 2.4rem;
    }
    .contactForm6 {
        margin: 0 auto;
    }
    .contactForm7 {
        margin-left: 20%;
        margin-top: 2.75rem;
    }
    .contact-copy-wrap {
        position: relative;
        width: fit-content;
    }
    .contact-popup {
        font-size: 1.25rem;
        bottom: -1.5rem;
        left: -1rem;
    }
    @keyframes copyPopup {
        50% {opacity: 1}
        75% {opacity: 1}
        100% {opacity: 0; bottom: -2.5rem;}
    }
    @-webkit-keyframes copyPopup {
        50% {opacity: 1}
        75% {opacity: 1}
        100% {opacity: 0; bottom: -2.5rem;}
    }
}
@media (max-width: 400px) {
    .contactForm7 {
        margin-left: 16.5%;
    }
}
@media (max-width: 380px) {
    .contactForm7 {
        margin-left: 15%;
    }
}
@media (max-width: 359px) {
    .contactForm7 {
        margin-left: 12.5%;
    }
}