.blogs-container {
    padding: 3.5rem 0 3.5rem 18.5rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.blog-header {
    position: relative;
    color: white;
    font-size: 6.25rem;
    font-weight: 600;
    animation: skilsHeadFade 2s ease forwards;
    margin-top: 3.5rem;
    margin-bottom: -3.5rem;
    opacity: 0;
}
.blog-header span {
    display: inline-block;
    transition: color .35s ease;
}
.blog-header span:hover {
    color: #53a7d1;
    animation: contactBounce 1s ease forwards
}
.blog-description {
    margin-top: 3rem;
    font-size: 1.85rem;
    opacity: 0;
    animation: blogDesc 1.75s ease 1.5s forwards;
}
@keyframes blogDesc {
    100% {opacity: 1}
}
@-webkit-keyframes blogDesc {
    100% {opacity: 1}
}
.blog-wrapper {
    margin-top: 2.5rem;
    width: 90%;
    height: 57.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform-style: preserve-3d;
    opacity: 0;
    margin-top: 7.5rem;
    animation: blogWrapUp 1s ease .75s forwards;
}
@keyframes blogWrapUp {
    100% {opacity: 1; margin-top: 5rem}
}
@-webkit-keyframes blogWrapUp {
    100% {opacity: 1; margin-top: 5rem}
}
.blog-wrapper input[type=radio] {
    display: none;
}
.blog-cards {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}
.blog-card {
    position: absolute;
    width: 70rem;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.5s ease;
    border-radius: 1rem;
}
#blog-item-1:checked ~ .blog-cards #blog-slide-3, #blog-item-2:checked ~ .blog-cards #blog-slide-1, #blog-item-3:checked ~ .blog-cards #blog-slide-2 {
    transform: translatex(-40%) scale(0.8);
    opacity: 0.35;
    z-index: 0;
}
#blog-item-1:checked ~ .blog-cards #blog-slide-2, #blog-item-2:checked ~ .blog-cards #blog-slide-3, #blog-item-3:checked ~ .blog-cards #blog-slide-1 {
    transform: translatex(40%) scale(0.8);
    opacity: 0.35;
    z-index: 1;
}
#blog-item-1:checked ~ .blog-cards #blog-slide-1, #blog-item-2:checked ~ .blog-cards #blog-slide-2, #blog-item-3:checked ~ .blog-cards #blog-slide-3 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 2;
}
.blog-content {
    height: 100%;
    width: 100%;
}
.blog-image {
    width: 100%;
    height: 25rem;
    border-radius: 1rem 1rem 0 0;
    object-fit: cover;
}
.blog-text-content {
    height: 21.5rem;
    border-bottom: 1px solid gray;
    color: black;
    background-color: #53a7d1;
    padding: 1rem 2rem;
}
.blog-title {
    font-size: 2.85rem;
    color: #121212;
    text-shadow: 1.5px 1.5px 1px gray;
    padding: .5rem 0 1rem;
}
.blog-title a {
    color: #121212;
    text-decoration: none;
}
.blog-title a:hover {
    text-decoration: underline;
    cursor: pointer;
}
.blog-start-wrap {
    width: calc(100% + 4rem);
    height: 100%;
    margin-left: -2rem;
    padding: 0 2.5rem;
    background-image: linear-gradient(to bottom, transparent, black)
}
.blog-start {
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: #181818;
}
.blog-start b {
    display: inline-block;
    margin-top: .5rem;
    margin-bottom: -.5rem;
    line-height: 2.65rem;
}
.blog-start a {
    color: #121212;
    text-decoration: underline;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 1rem;
    z-index: 90;
}
.blog-start a:hover {
    cursor: pointer;
    color: #242424;
}
.blog-facts {
    position: relative;
    background-color: #252525;
    border-top: 2px solid #222;
    border-radius: 0 0 1rem 1rem;
    height: 6.25rem;
    font-size: 1.75rem;
    color: #888;
}
.blog-date {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5rem;
    font-weight: 400;
    color: #585858;
}
.blog-read {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.9rem;
    font-weight: 500;
}
.blog-likes {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    right: 5rem;
    color: #656565;
    -webkit-text-stroke: 1px #222;
    font-size: 1.85rem;
    font-weight: 900;
    font-style: italic;
}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .blogs-container {
        padding: 5rem 0 3.5rem 18.5rem;
    }
    .blog-card {
        width: 65rem;
    }
    .blog-image {
        height: 22.5rem;
    }
    .blog-start {
        font-size: 1.6rem;
    }
}
@media (max-width: 1250px) {
    .blogs-container {
        padding: 7.5rem 0 3.5rem 16.5rem;
    }
    .blog-card {
        width: 57.5rem;
    }
    .blog-image {
        height: 20rem;
    }
    .blog-text-content {
        height: 18.5rem;
    }
    .blog-start b {
        margin-top: .125rem;
        margin-bottom: -.5rem;
        line-height: 2rem;
    }
    #blogStartResponsive {
        display: none;
    }
    .blog-title {
        font-size: 2.55rem;
    }
    .blog-likes {
        font-size: 1.8rem;
    }
}
@media (max-width: 1100px) {
    .blogs-container {
        padding: 7.5rem 0 3.5rem 0;
    }
    .blog-title {
        font-size: 2.5rem;
        padding: .35rem 0 .75rem;
    }
}
@media (max-width: 950px) {
    .blog-title {
        font-size: 2.125rem;
    }
    .blogs-container {
        padding: 5rem 0 3.5rem 0;
    }
    .blog-card {
        width: 50rem;
    }
    .blog-image {
        height: 16.5rem;
    }
    .blog-start {
        font-size: 1.4rem;
    }
    .blog-start a {
        font-size: 1.65rem;
    }
    .blog-date {
        left: 2.5rem;
    }
    .blog-likes {
        right: 3.5rem;
    }
    .blog-start-wrap {
        padding: 0 2rem;
    }
}
@media (max-width: 850px) {
    .blog-title {
        font-size: 1.75rem;
        padding: .125rem 0 .75rem;
        margin-left: -.75rem;
    }
    .blog-header {
        font-size: 5rem;
    }
    .blog-description {
        margin-top: 2.25rem;
        font-size: 1.65rem;
    }
    .blogs-container {
        padding: 5rem 0 3.5rem 0;
    }
    .blog-card {
        width: 40rem;
    }
    .blog-cards {
        margin-top: -1rem;
    }
    #blog-item-1:checked ~ .blog-cards #blog-slide-3, #blog-item-2:checked ~ .blog-cards #blog-slide-1, #blog-item-3:checked ~ .blog-cards #blog-slide-2 {
        transform: translatex(-30%) scale(0.8);
    }
    #blog-item-1:checked ~ .blog-cards #blog-slide-2, #blog-item-2:checked ~ .blog-cards #blog-slide-3, #blog-item-3:checked ~ .blog-cards #blog-slide-1 {
        transform: translatex(30%) scale(0.8);
    }
    .blog-text-content {
        height: 19.5rem;
    }
    .blog-image {
        height: 15rem;
    }
    .blog-start {
        font-size: 1.4rem;
    }
    .blog-start a {
        font-size: 1.5rem;
    }
    .blog-start-wrap {
        padding: 0 1.5rem;
    }
    .blog-date {
        left: 2rem;
        font-size: 1.35rem;
    }
    .blog-read {
        font-size: 1.5rem;
    }
    .blog-likes {
        right: 2rem;
        font-size: 1.5rem;
    }
    .blog-facts {
        height: 5rem;
    }
}
@media (max-width: 650px) {
    .blogs-container {
        padding: 2rem 0 2rem 0;
    }
    .blog-title {
        font-size: 1.55rem;
        padding: .25rem 0 1rem;
        margin-left: .25rem;
    }
    .blog-header {
        font-size: 4rem;
    }
    .blog-description {
        margin-top: 2.25rem;
        font-size: 1.25rem;
        padding: 0 2rem;
        text-align: center;
    }
    .blog-card {
        width: 27rem;
    }
    .blog-cards {
        margin-top: .5rem;
    }
    .blog-text-content {
        height: 23.5rem;
        padding: 1rem .25rem;
    }
    .blog-image {
        height: 13.5rem;
    }
    .blog-start {
        font-size: 1.2rem;
        line-height: 1.5;
    }
    .blog-start a {
        font-size: 1.375rem;
    }
    #blog-item-1:checked ~ .blog-cards #blog-slide-3, #blog-item-2:checked ~ .blog-cards #blog-slide-1, #blog-item-3:checked ~ .blog-cards #blog-slide-2 {
        transform: translatex(-25%) scale(0.8);
    }
    #blog-item-1:checked ~ .blog-cards #blog-slide-2, #blog-item-2:checked ~ .blog-cards #blog-slide-3, #blog-item-3:checked ~ .blog-cards #blog-slide-1 {
        transform: translatex(25%) scale(0.8);
    }
    .blog-start-wrap {
        padding: 0 .75rem;
        margin-left: -.25rem;
        width: calc(100% + .5rem);
    }
    .blog-start b {
        margin-top: .65rem;
    }
    .blog-start i {
        font-size: 1.2rem;
        line-height: 1;
    }
    .blog-date {
        left: 1rem;
        font-size: 1rem;
    }
    .blog-read {
        font-size: 1.2rem;
    }
    .blog-likes {
        right: 1.125rem;
        font-size: 1rem;
        margin-top: 1px;
    }
    .blog-facts {
        height: 5rem;
    }
    .blog-card {
        border-radius: .5rem;
    }
    .blog-image {
        border-radius: .5rem .5rem 0 0;
    }
    .blog-facts {
        border-radius: 0 0 .5rem .5rem;
    }
}